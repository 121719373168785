
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    showQR: {
      default: true,
      type: Boolean,
    },
    size: {
      default: 200,
      type: Number,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    birthday: String,
    gender: String,
    homePhone: String,
    homeEmail: String,
    homeStreet: {
      default: "",
      type: String,
    },
    homeCity: {
      default: "",
      type: String,
    },
    homeRegion: {
      default: "",
      type: String,
    },
    homePost: {
      default: "",
      type: String,
    },
    homeCountry: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      vCardStart: "BEGIN:VCARD\nVERSION:3.0\n",
      vCardEnd: "\nEND:VCARD",
    };
  },
  computed: {
    personalInfo: function (): string {
      return `N:${this.lastName};${this.firstName}\nFN:${this.firstName} ${
        this.lastName
      }${this.birthday ? `\nBDAY:${this.birthday}` : ""}${
        this.gender ? `\nX-GENDER:${this.gender}` : ""
      }`;
    },

    contactInformation: function (): string {
      return `${this.homePhone ? `\nTEL;TYPE=home:${this.homePhone}` : ""}${
        this.homeEmail ? `\nEMAIL;TYPE=internet,home:${this.homeEmail}` : ""
      }`;
    },

    addressDetails: function (): string {
      if (
        this.homeStreet +
          this.homeCity +
          this.homeRegion +
          this.homePost +
          this.homeCountry !==
        ""
      ) {
        return `\nADR;TYPE=home:;;${this.homeStreet};${this.homeCity};${this.homeRegion};${this.homePost};${this.homeCountry}`;
      }

      return "";
    },

    generateString: function (): string {
      let vString =
        this.vCardStart +
        this.personalInfo +
        this.contactInformation +
        this.addressDetails +
        this.vCardEnd;
      let QRString = vString.replace(/\n/g, "%0A");
      this.$emit("QRString", QRString);
      // console.log(QRString);
      return QRString;
    },
  },
});
