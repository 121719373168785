import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showQR)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: 
      'https://api.qrserver.com/v1/create-qr-code/?size=' +
      _ctx.size +
      'x' +
      _ctx.size +
      '&data=' +
      _ctx.generateString
    
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}