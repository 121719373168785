import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "self-center mr-4 text-lg font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["flex border border-2 border-current rounded-md", _ctx.color]),
    href: _ctx.link,
    target: "_blank",
    rel: "noopener"
  }, [
    _createVNode(_component_vue_feather, {
      type: _ctx.icon,
      class: "m-4 my-4",
      "stroke-width": "2"
    }, null, 8, ["type"]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ], 10, _hoisted_1))
}