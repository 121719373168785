
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: String,
    link: String,
    icon: String,
    colorName: String,
  },
  computed: {
    color() {
      return `text-${this.colorName}`;
    },
  },
});
